<template>
  <div>
    <!-- 头图 -->
    <div class="AboutBanner">
      <img src="@/assets/img/about/banner-about.jpg" />
    </div>
    <!-- 公司介绍 -->
    <div class="AboutIntroduction">
      <!-- 栏目标题 -->
      <div class="columnTitle">
        <div>company information</div>
        <div>
          <div>公司介绍</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="AboutIntroduction_content">
        <div>
          <img src="@/assets/img/about/pic-company.png" />
        </div>
        <div>
          广州微林软件有限公司成立于2016年，位于广州市科学城基地的生产力促进中心。微林软件是一家专注于视觉检测和视觉工业自动化的高新企业，通过CV、DL等各种技术，努力打造视觉引导工业自动化的人工智能，极大促进生产力的发展。<br />
          微林软件高度重视自主创新，自公司成立以来，先后研发了具有自主知识产权的三维立体测距、手掌检测、二维码快速识别、视觉高精度测量（尺寸测量、计数、缺陷检测）等相关核心技术。并成功开发了具有自主软件著作权的服务端设备监控、平台化设备监控、机械臂控制、工业设备视觉检测、设备监控等系统检测软件。为客户研制出的高精度视觉对位系统、以及高可靠性的自动化检测设备及整体解决方案，同时可供应配套的工业相机、工业镜头、视觉光源及专业图像处置软件等标准产品。还为客户提供项目评价、计划设计、设备调试、技能咨询及训练等一体化的专业服务。<br />
          广州微林软件有限公司正处于高速发展时期，我们除了拥有领先水平的机器视觉商品之外，还能够获得提供的全方位的服务与支持，然后进一步为客户为客户提供全方位的协助。如果是接受过技能训练的直销人员，不仅仅只能处置疑难的的实践使用问题，还能随时处理与我们商品有关的任何技能疑问。公司全体员工将秉承＂炼就火眼金睛，成就智能机器＂的使命，朝着＂2025年，成为视觉领域的知名企业＂这一伟大愿景而迈进！
        </div>
      </div>
    </div>
    <!-- 经营战略 -->
    <div class="AboutOperating">
      <div class="AboutOperating_info">
        <div>经营战略</div>
        <div>
          在视觉识别领域及人工智能领域成为不可或缺的行业先锋，为促进人们的生活智能化而努力。我们立足于自主创新，并通过不断完善，研发出拥有自主知识产权的核心技术，根据客户特点，提出完整的解决方案。公司坚持以质量求生存、客户至上的方针，建立一个高效的研发生产及售后服务体系，全面解决客户的难题，为企业提供降低劳动力成本、提高品质及效率的完善方案；为用户提供最匹配的家居管理。
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="aboutUs_course">
      <div>
        <!-- 栏目标题 -->
        <div class="AboutTitle">
          <div>DEVELOPMENT HISTORY</div>
          <div>
            <div>发展历程</div>
            <div class="AboutTitle_bg">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="historyRecord">
          <!-- 日期侧边栏 -->
          <div class="dateRecord">
            <div
              v-for="(item, index) in historyRecord"
              :key="index"
              @click="onCurrentChange(index, item.list)"
            >
              <div :class="selectCurrent == index ? 'dateRecord_active' : ''">
                {{ item.time }}
              </div>
              <div
                :class="
                  selectCurrent == index
                    ? 'dateRecord_dianActive'
                    : 'dateRecord_dian'
                "
              ></div>
            </div>
          </div>
          <!-- 历程内容 -->
          <div class="historyContent">
            <van-swipe
              @change="handleSwipeChange"
              style="height: 150px"
              vertical
              ref="vSwipe"
              :loop="false"
              :show-indicators="false"
              :initial-swipe="selectCurrent"
            >
              <van-swipe-item
                v-for="(item, index) in historyRecord"
                :key="index"
              >
                <div class="historyContent_list">
                  <div
                    class="historyContent_item"
                    v-for="(node, idx) in item.list"
                    :key="idx"
                  >
                    {{ node }}
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
    </div>
    <!-- 团队荣誉 -->
    <div class="HonorColumn">
      <!-- 栏目标题 -->
      <div class="columnTitle">
        <div>team honor</div>
        <div>
          <div>团队荣誉</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <img
            class="imgCaseStudy"
            v-for="(item, index) in certificate"
            :src="item.imgUrl"
            :key="index"
            @click="handleImgPreview(item.imgUrl)"
          />
        </div>
        <div>
          <div class="HonorTitle">
            <span>专利</span>
            <span>patents</span>
          </div>
          <div class="patentBox">
            <img
              @click="handleImgPreview(item.imgUrl)"
              v-for="(item, index) in patent.slice(0, 4)"
              :key="index"
              class="imgCaseStudy"
              :src="item.imgUrl"
            />
          </div>
          <div class="patentlist">
            <img
              @click="handleImgPreview(item.imgUrl)"
              class="imgCombination"
              v-for="(item, index) in patent.slice(4, 7)"
              :key="index"
              :src="item.imgUrl"
            />
          </div>
        </div>
        <div>
          <div class="HonorTitle">
            <span>软件著作权</span>
            <span>Software copyright</span>
          </div>
          <div class="software">
            <img
              @click="handleImgPreview(list[0].imgUrl)"
              class="softwareImg"
              :src="list[0].imgUrl"
            />
            <img
              @click="handleImgPreview(list[1].imgUrl)"
              class="softwareImg"
              :src="list[1].imgUrl"
            />
          </div>
          <div class="softwareList">
            <img
              @click="handleImgPreview(item.imgUrl)"
              class="softwareList_img"
              v-for="(item, index) in list.slice(2, 5)"
              :key="index"
              :src="item.imgUrl"
            />
          </div>
        </div>
      </div>
      <div class="HonorColumn_page">
        <el-pagination
          background
          small
          :pager-count="5"
          layout="prev, pager, next"
          :total="softwareCopyright.length"
          :page-size="5"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <van-image-preview v-model="show" :images="images"> </van-image-preview>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //荣誉证书
      certificate: [
        {
          imgUrl: require("@/assets/img/honor/High-tech Enterprise Certificate.png"),
        },
        { imgUrl: require("@/assets/img/honor/Software certificate.jpg") },
      ],
      // 证书
      patent: [
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/certificate-1.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/certificate-14.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Vision-based guidance system.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/About the identification method of grabbed items.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Bin food identification method.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Hand-held article tracking behavior determination method.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Invention patent application.png"),
        },
      ],
      // 软件著作权
      softwareCopyright: [
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Bingmo Refrigerator Food Management Software2.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Barcode Dynamic Recognition Software2.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmo goods visual operation management software V1.0.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmo Shop.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmoo Mini Program.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Micro-Lin carpet cutting line visual detection and positioning software V1.0.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Relay zero-crossing on-off control and setting software.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin binocular vision software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Communication Center Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Event Center Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Industrial Equipment Visual Inspection Software V1.0.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Monitoring Hard Disk Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Palm Recognition Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin PC terminal equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin platform equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin QR code quick recognition software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin robotic arm control software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin server equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Car Light Inspection.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Cotton Mask Detection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision J-type metal detection software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Metal Spring Inspection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision PCB Board Recognition and Positioning Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software-USB Inspection.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software-Volume Dynamic Recognition.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Vehicle Workpiece Inspection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin visual capacitance positive and negative detection software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin WeChat terminal equipment monitoring software.jpg"),
        },
      ],
      // 暂存数组
      list: [],
      // 历史记录
      historyRecord: [
        {
          time: "2021",
          list: [
            "2021年08月冰眸小程序完成升级，APP在各大应用市场上架",
            "2021年04月冰眸二期改版完成，项目整体规划完成",
          ],
        },
        {
          time: "2020",
          list: [
            "2020年10月冰眸项目核心算法模型完成，申请6项专利",
            "2020年04月视觉检测产品被认定为广东省高新科技产品",
          ],
        },
        {
          time: "2019",
          list: [
            "2019年12月获认证为广东省第一批国家高新科技企业",
            "2019年05月完成视觉检测项目，机械臂引导控制的研发",
          ],
        },
        {
          time: "2018",
          list: ["2018年02月获得国家专利，知识产权管理体系认证"],
        },
        {
          time: "2017",
          list: [
            "2017年01月广州微林软件有限公司正式挂牌成立，开始涉足视觉检测行业",
          ],
        },
      ],
      // 选中当前
      selectCurrent: 0,
      // 预览弹窗
      show: false,
      // 预览路径
      images: [],
      // 滑动状态
      slidingState: true,
    };
  },
  created() {
    this.list = this.softwareCopyright.slice(0, 5);
  },
  methods: {
    // 处理分页切换
    handleCurrentChange(page) {
      this.$nextTick(() => {
        this.list = [...this.softwareCopyright.slice(page * 5 - 5, page * 5)];
      });
    },
    // 处理当前日期选中
    onCurrentChange(index) {
      this.$refs.vSwipe.swipeTo(index);
    },
    // 处理轮播图切换
    handleSwipeChange(index) {
      this.$nextTick(() => {
        this.selectCurrent = index;
      });
    },
    // 处理图片预览
    handleImgPreview(url) {
      this.images = [url];
      this.show = true;
    },
    // 监听滚动
    onScrollChange(index) {
      console.log(index);
      // this.slidingState = false;
    },
  },
};
</script>

<style scoped>
/* 关于我们首图 */
.AboutBanner img {
  width: 100%;
  height: 136px;
  object-fit: cover;
  display: flex;
}
/* 公司介绍 */
.AboutIntroduction {
  margin: 40px 0;
}
/* 栏目标题 */
.columnTitle {
  position: relative;
  margin: 40px 0;
}
.columnTitle > div:nth-of-type(1) {
  font-size: 24px;
  color: #e5e5e5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.columnTitle > div:nth-of-type(2) {
  font-size: 20px;
  color: #002780;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.columnTitle_bg {
  position: relative;
  margin-top: 10px;
}
.columnTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 40px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.columnTitle_bg > div:nth-of-type(2) {
  width: 120px;
  height: 1px;
  background-color: #000;
}
/* 内容 */
.AboutIntroduction_content {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.AboutIntroduction_content > div {
  width: 100%;
  padding: 0 14px;
  box-sizing: border-box;
}
.AboutIntroduction_content > div:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 30px;
  text-indent: 2em;
  margin-top: 20px;
}
.AboutIntroduction_content img {
  width: 100%;
  height: 260px;
  display: flex;
  object-fit: cover;
}
/* 经营战略 */
.AboutOperating {
  position: relative;
  background-image: url("../assets/img/about/bg-company-zl.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.AboutOperating_info {
  width: 100%;
  padding: 40px 17px;
  box-sizing: border-box;
}
.AboutOperating_info > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 500;
  color: #002780;
  text-align: center;
}
.AboutOperating_info > div:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #5f7fb5;
  margin-top: 20px;
  box-sizing: border-box;
}
/* 历程 */
.aboutUs_course {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
}
/* 发展历程内容 */
.historyRecord {
  width: 100%;
  height: 150px;
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: space-between;
}
.dateRecord {
  width: 25%;
  height: 100%;
  color: #ccc;
  display: flex;
  font-size: 13px;
  transition: all 0.5s;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.dateRecord > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 6px 0;
  cursor: pointer;
}
.dateRecord_dian {
  right: -3px;
  width: 6px;
  height: 6px;
  margin-left: 20px;
  position: relative;
  transition: all 0.5s;
  border-radius: 100%;
  background-color: #cccccc;
}
.dateRecord_active {
  font-size: 20px;
  font-weight: 500;
  color: transparent;
  background: linear-gradient(to right, #004eff, #002780);
  background-clip: text;
}
.dateRecord_dianActive {
  width: 10px;
  height: 10px;
  right: -5px;
  margin-left: 20px;
  position: relative;
  border-radius: 100%;
  transition: all 0.5s;
  background: linear-gradient(to right, #004eff 0%, #002780 100%);
}

/* 历程内容 */
.historyContent {
  width: 65%;
  height: 100%;
}
.historyContent_list {
  height: 150px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.historyContent_list::-webkit-scrollbar {
  display: none;
}
.historyContent_item {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-top: 20px;
}
/* 团队荣誉 */
.HonorColumn {
  width: 100%;
  padding: 40px 20px;
  background-color: #f5f5f5;
  box-sizing: border-box;
}
.imgCaseStudy {
  width: 100%;
  height: 226px;
  display: flex;
  object-fit: cover;
  margin-bottom: 30px;
}
.HonorTitle {
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #002780;
}
.HonorTitle > span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  margin-right: 12px;
}
.HonorTitle > span:nth-of-type(2) {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
/* 证书 */
.patentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.patentBox > img {
  width: calc(50% - 10px);
}
.HonorColumn_page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.patentlist {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgCombination {
  width: calc(33% + 61px - 20px);
}
.imgCombination:nth-of-type(2) {
  position: relative;
  left: -61px;
}
.imgCombination:nth-of-type(3) {
  position: relative;
  left: -124px;
}
/* 软件著作 */
.software {
  margin-bottom: 30px;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.softwareImg {
  width: calc(50% - 10px);
  display: flex;
  object-fit: cover;
}
.softwareList {
  padding-bottom: 10px;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.softwareList_img {
  width: calc(33% - 8px);
  display: flex;
  object-fit: cover;
}
/* 栏目标题-适配 */
.AboutTitle {
  position: relative;
}
.AboutTitle > div:nth-of-type(1) {
  font-size: 24px;
  color: #e5e5e5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AboutTitle > div:nth-of-type(2) {
  font-size: 20px;
  color: #002780;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.AboutTitle_bg {
  position: relative;
  margin-top: 10px;
}
.AboutTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 40px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AboutTitle_bg > div:nth-of-type(2) {
  width: 120px;
  height: 1px;
  background-color: #000;
}
.HonorColumn_page >>> .active {
  background-color: #002780 !important;
}
</style>